import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'MSR Slab-Beam Diagram' },
    { img: data.i2, caption: 'Slab Beam - Above' },
    { img: data.i3, caption: 'Epicore MSR Slab Beams - Below' },
    { img: data.i4, caption: 'Slab Beam on TS Columns from Above' },
    { img: data.i5, caption: 'Slab Beams Continuous Type' },
    { img: data.i6, caption: 'Slab Beam Columns w/ Reshoring' },
    { img: data.i7, caption: 'Slab Beams Across Corridor' },
    { img: data.i8, caption: 'Slab Beams Across Corridor' },
    { img: data.i9, caption: 'Slab Beams from Below with Ducts' },
  ];

  return (
    <Layout>
      <SEO title="System Overview: EPICORE MSR Slab Beams" keywords={[]}/>
      <div className="container system-overview-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Overview</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Slab Beams</h1>

        <div className='grid grid--gutterless'>
          <div className='grid__column grid__column--12 grid__column--4--md'>
            <p>The Epicore MSR Slab-Beam offers a very versatile method of clear-spanning
            an opening, rooms and corridors where a load-bearing metal stud wall or steel
            beam is undesirable. The slab-beam width is basically designed at widths up to
            3’-10” (so that it is the width of a sheet of plywood minus the Epicore MSR
            deck bearing on the edge of the plywood). The depth is the same as the Epicore
            MSR Slab.  Since the slab-beam is the same depth as the Epicore MSR slab, the
            electrical, sprinkler,  HVAC ducts, etc., can run past it unobstructed, allowing
            you to maintain maximum ceiling heights. The slab-beam is solid concrete, so
            it does not require fireproofing like a steel beam or CFS box header.</p>
            <p className='type--lg'>
              <Link className="link-next" to="/system-overview/rebar-headers/">
                Next Page: Rebar Headers
              </Link>
            </p>
            <p>
              <Link className="link-prev" to="/system-overview/pouring-concrete/">
                Prev Page: Pouring Concrete
              </Link>
            </p>
          </div>

          <div className='grid__column grid__column--12 grid__column--8--md'>
            <div className='carousel-container carousel-container--padding-x'>
              <Carousel
                content={images}
                carWidth={474}
                carHeight={316}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-overview/slab-beams/gallery/01-41619.png" }) {
            ...SystemOverviewCarouselTechnical1_5ImageFragment },
        i2: file(relativePath: { eq: "system-overview/slab-beams/gallery/02-SlaBe280_(34308).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i3: file(relativePath: { eq: "system-overview/slab-beams/gallery/03-SlaBe110_(34258).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i4: file(relativePath: { eq: "system-overview/slab-beams/gallery/04-SlaBe290_(34244).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i5: file(relativePath: { eq: "system-overview/slab-beams/gallery/05-SlaBe140_(40262).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i6: file(relativePath: { eq: "system-overview/slab-beams/gallery/06-SlaBe300_(43030).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i7: file(relativePath: { eq: "system-overview/slab-beams/gallery/07-SlaBe010_(42991).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i8: file(relativePath: { eq: "system-overview/slab-beams/gallery/08-SlaBe080_(42998).png" }) {
            ...SystemOverviewCarouselImageFragment },
        i9: file(relativePath: { eq: "system-overview/slab-beams/gallery/09-SlaBe160_(40264).png" }) {
            ...SystemOverviewCarouselImageFragment },
    }
`
